<script lang="ts" setup>
type Props = {
  headingLevel?: number;
  subHeadingClass?: string;
};
const props = withDefaults(defineProps<Props>(), {
  headingLevel: 3,
  subHeadingClass: "text-primary-darken-2",
});
</script>

<template>
  <section class="section d-flex flex-column content-container">
    <div
        v-if="$slots.title && $slots.subtitle"
      class="d-flex flex-column align-center text-center mb-3 w-100 mx-auto section-content-wrapper"
    >
      <div class="order-3" v-if="$slots.title">
        <MDCSlot unwrap="p" name="title" />
      </div>
      <div
        class="w-100 order-2 section-header-divider"
        v-if="$slots.title && $slots.subtitle"
      >
        <v-divider />
      </div>
      <component
        :is="'h' + headingLevel"
        :class="'text-subheadline mx-auto order-1 ' + subHeadingClass"
        v-if="$slots.subtitle"
      >
        <MDCSlot unwrap="p" name="subtitle" />
      </component>
    </div>
    <MDCSlot name="default" />
  </section>
</template>

<style lang="scss">
@use "assets/scss/settings";
@use "~/../_common/assets/scss/mixins";

.section {
  &.content-container {
    margin: auto;

    @include mixins.media-query("lg") {
      max-width: 1280px;
    }

    @media only screen and (min-width: 1900px) {
      // decreased container width because of TOC menu, which is shown starting from 1900px
      max-width: 1120px;
    }
    @include mixins.media-query("xl-and-up") {
      max-width: 1600px;
    }
  }

  .section-content-wrapper {
    max-width: 900px;
  }

  .section-header-divider {
    max-width: 50%;
  }
}
</style>
